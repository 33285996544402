import React, { createRef, useEffect } from 'react';
import { agentSalesFlowToastMessageQuotes } from '../../utils/toastMessages';
import { openNotification, showMultipleToasts } from '../../utils/apiSetup';
import showToastMessage from '../../utils/showToastMessage';
import { useTranslation } from 'react-i18next';
import { customerDetailsPath } from '../../router/paths';
import PropTypes from 'prop-types';

export const AgentSalesFlowWidget = ({
  policyUuid,
  partyId,
  localization,
  mode = '',
  setPolicyStatus,
  queryParameters,
  disableProductSelection,
  enableClauseSearch,
  enableRiskToggleOnLeft,
  enableDatepicker,
  history,
  match,
  policyUuidVersionDate,
}) => {
  const widgetRef = createRef();
  const { t } = useTranslation();

  useEffect(() => {
    const handleOnSuccessAction = (event) => {
      const toastMessage = agentSalesFlowToastMessageQuotes(event, t);
      const { mode: flowMode } = JSON.parse(queryParameters);

      if (flowMode && flowMode === 'setinforce') {
        history.push(customerDetailsPath(match.params.customerid));
      }

      if (toastMessage) {
        showToastMessage({ message: toastMessage.messageText, type: toastMessage.messageType });
      }

      if (event.detail.payload.message) {
        event.detail.payload.message.forEach((message) => openNotification({ title: message.title, message: message.message, severityLevel: message.severityLevel, messageCode: message.messageCode }));
      }

      if (event.detail.payload.messages) {
        event.detail.payload.messages.forEach((messages) => openNotification({ title: messages.title, message: messages.message, severityLevel: messages.severityLevel, messageCode: messages.messageCode }));
      }
    };

    const handleMessage = (event) => showMultipleToasts(event.detail);
    const handlePolicyLoad = (event) => {
      if (setPolicyStatus) {
        try {
          setPolicyStatus(event.detail.salesPolicy.salesProductHeader.policyStatus);
        } catch (exception) {
          console.error('Policy load failed');
        }
      }
    };

    if (widgetRef && widgetRef.current) {
      widgetRef.current.addEventListener('success-widget-action', handleOnSuccessAction);
      widgetRef.current.addEventListener('widget-message', handleMessage);
      widgetRef.current.addEventListener('widget-error', handleMessage);
      widgetRef.current.addEventListener('widget-policy-load', handlePolicyLoad);
    }

    return () => {
      if (widgetRef && widgetRef.current) {
        widgetRef.current.removeEventListener('success-widget-action', handleOnSuccessAction);
        widgetRef.current.removeEventListener('widget-message', handleMessage);
        widgetRef.current.removeEventListener('widget-error', handleMessage);
        widgetRef.current.removeEventListener('widget-policy-load', handlePolicyLoad);
      }
    };
  }, [t, policyUuidVersionDate]);

  return (
    <tia-agent-sales-flow-widget
      policyUuid={policyUuid}
      partyId={partyId}
      ref={widgetRef}
      mode={mode}
      localization={localization}
      queryParameters={queryParameters}
      disableProductSelection={disableProductSelection}
      enableClauseSearch={enableClauseSearch}
      enableRiskToggleOnLeft={enableRiskToggleOnLeft}
      enableDatepicker={enableDatepicker}
      policyUuidVersionDate={policyUuidVersionDate && policyUuidVersionDate.split('T')[0]}
    />
  );
};

AgentSalesFlowWidget.propTypes = {
  policyUuid: PropTypes.string,
  partyId: PropTypes.string,
  localization: PropTypes.string,
  mode: PropTypes.string,
  setPolicyStatus: PropTypes.func,
  queryParameters: PropTypes.string,
  disableProductSelection: PropTypes.bool,
  enableClauseSearch: PropTypes.bool,
  enableRiskToggleOnLeft: PropTypes.bool,
  enableDatepicker: PropTypes.bool,
  history: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  match: PropTypes.object,
  policyUuidVersionDate: PropTypes.string,
};
