import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Menu } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getMenuItemsListBasedOnRoles } from '../../router/helpers';
import './LeftMenu.scss';
import { getAgentConfig } from '../../state/api/configurations/selectors';
import { withTranslation } from 'react-i18next';
import randomKey from '../../utils/randomKey';
import { PATH_CRM, PATH_CUSTOM_WIDGET } from '../../router/paths';
import { getFeatureFlagsConfiguration } from '../../utils/getGlobalConfiguration';
import { featureFlags } from '../../components/FeatureFlag/featureFlagConst';

class LeftMenu extends Component {
  constructor(props) {
    super(props);
    const { history } = this.props;

    const currentPath = history.location.pathname;
    const path = currentPath.split('/');

    this.state = {
      openKey: path,
    };
  }

  onOpenChange = (openKeys) => {
    this.setState({ openKey: openKeys });
  };

  render() {
    const fallbackLogo = window.agentEnv.contextPath
      ? `${window.agentEnv.contextPath}/img/logo/sapiens.svg`
      : '/img/logo/sapiens.svg';
    const { t, collapsed, toggle, logoSrc } = this.props;
    const { openKey } = this.state;

    const menuHighlight = openKey;
    const logo = logoSrc || window.agentEnv.logo || fallbackLogo;
    const menuItems = getMenuItemsListBasedOnRoles().map((element) => {
      if (
        (element.path === PATH_CRM && getFeatureFlagsConfiguration(featureFlags.crm.crmView.crmWidget)) ||
        (element.path === PATH_CUSTOM_WIDGET && getFeatureFlagsConfiguration("customWidget.enabled")) ||
        (element.path !== PATH_CRM && element.path !== PATH_CUSTOM_WIDGET)
        ) {
        return {
          key: randomKey(),
          label: (
            <Link to={element.path}>
              <span data-cy={element.translationKey.replace(/\./g, '-')}>{t(element.translationKey)}</span>
            </Link>
          ),
          icon: element.icon,
          style: { marginTop: '0px' },
        };
      }
    });

    return (
      <Layout.Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={() => {
          toggle();
        }}
        breakpoint="md"
        style={{
          WebkitBoxShadow: '2px -3px 5px -2px #ddd',
          BoxShadow: '2px -3px 5px -2px #ddd',
        }}>
        <div className="logo">
          <Link to="/">
            <img
              src={logo}
              style={{
                maxHeight: '100%',
                maxWidth: '100%',
                display: 'block',
                margin: 'auto',
              }}
              alt=""
            />
          </Link>
        </div>
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={menuHighlight}
          onOpenChange={this.onOpenChange}
          openKeys={openKey}
          items={menuItems}></Menu>
      </Layout.Sider>
    );
  }
}

const mapStateToProps = (state) => ({
  logoSrc: getAgentConfig(state, 'logo'),
});

export default withTranslation()(connect(mapStateToProps, null)(withRouter(LeftMenu)));

LeftMenu.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  rerender: PropTypes.bool,
  history: PropTypes.object,
  t: PropTypes.func,
  logoSrc: PropTypes.string,
};
