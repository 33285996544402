import { connectorFactory } from "@tia/api-query"
import { object, string } from "valibot"

export const { endpoints: queryEndpoints } = connectorFactory({
  api: 'spd_sales_ui_api',
  accessToken: 'tiaagent_access_token',
  debug: localStorage.getItem('is_debug_logging_enabled') === 'true',
},{
  createQuoteOnPolicy: {
    url: '/v1/sales-policies/:uuid/quote-on-policy',
    method: 'POST',
    responseSchema: object({ salesPolicyUUID: string() })
  },
  createQuoteOnRenewal: {
    url: '/v1/sales-policies/:uuid/quote-on-renewal',
    method: 'POST',
    responseSchema: object({ salesPolicyUUID: string() })
  }
})