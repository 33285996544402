import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { func, object } from 'prop-types';
import { Button, Col, Modal, Row } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { getQuote } from '../../state/pages/editQuote/selectors';
import { getCustomerDetails } from '../../state/pages/customer/selectors';
import { setRecentlyViewedCustomer } from '../../state/pages/customer/actionCreators';
import { _getPartyById as getPartyById } from '../../state/api/party/actionCreators';
import CustomerDetail from '../CustomerDetail/CustomerDetail';
import { CreatePaymentDetail } from '../Payment/CreatePaymentDetail';
import { editQuotePath } from '../../router/paths';
import { FeatureFlag } from '../../components/FeatureFlag/FeatureFlag';
import { featureFlags } from '../../components/FeatureFlag/featureFlagConst';
import { PolicyTimeline } from '../../widgets/PolicyTimelineWidget/PolicyTimeline';
import { AgentSalesFlowWidget } from '../../components/AgentSalesFlowWidget/AgentSalesFlowWidget';
import { Documents } from '../../widgets/DocumentsWidget/Documents.widget';
import { createQuoteOnPolicy, createQuoteOnRenewal } from '../../state/api/policies/actionCreators';
import showToastMessage from '../../utils/showToastMessage';
import { SearchMode } from '@tia/agent-api-connector/dist/enums/SearchMode';
import { showMultipleToasts } from '../../utils/apiSetup';
import { getFeatureFlagsConfiguration } from '../../utils/getGlobalConfiguration';
import { newClaimPath } from '../../router/paths';

const headerStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: '#fff',
  padding: '10px',
  border: '0.0625rem solid rgba(0,0,0,.09)',
  borderRadius: '4px',
  margin: '0 0 8px 0',
  boxShadow: '0 4px 9px 0 rgba(55, 92, 130, 0.11)',
};

const headlineStyles = {
  fontSize: '16px',
  fontWeight: 'bold',
  color: '#4e505e',
};

class Policies extends PureComponent {
  constructor(props) {
    super(props);
    this.fetchAssets();

    this.state = {
      showPaymentModal: false,
      showCustomWidget: false,
      policyStatus: '',
      clickedPolicy: { policyVersionDate: '', status: 'active' },
    };
  }

  setClickedPolicyVersionDateAndStatus = ({ policyVersionDate, status }) => {
    this.setState({ clickedPolicy: { policyVersionDate: policyVersionDate, status: status } });
  };

  setPolicyStatus = (policyStatus) => {
    this.setState({ policyStatus });
  };

  handleAddPaymentDetails = () => {
    this.setState({
      showPaymentModal: true,
    });
  };

  handleShowCustomWidget = () => {
    this.setState({
      showCustomWidget: true,
    });
  };

  closePaymentModal = () => {
    this.setState({
      showPaymentModal: false,
      showCustomWidget: false,
    });
  };

  openCreateQuoteOnPolicyModal = (t) => {
    Modal.confirm({
      title: t('widgets.policyDetails.createQuoteOnPolicyModal.title'),
      content: t('widgets.policyDetails.createQuoteOnPolicyModal.content'),
      okText: t('widgets.policyDetails.createQuoteOnPolicyModal.okText'),
      cancelText: t('widgets.policyDetails.createQuoteOnPolicyModal.cancelText'),
      mask: false,
      centered: true,
      okButtonProps: { 'data-cy': 'modal-ok-button' },
      cancelButtonProps: { 'data-cy': 'modal-cancel-button' },
      onOk: () => this.handleCreateQuoteOnPolicy(),
    });
  };

  openCreateQuoteOnRenewalModal = (t) => {
    Modal.confirm({
      title: t('widgets.policyDetails.createQuoteOnRenewalModal.title'),
      content: t('widgets.policyDetails.createQuoteOnRenewalModal.content'),
      okText: t('widgets.policyDetails.createQuoteOnRenewalModal.okText'),
      cancelText: t('widgets.policyDetails.createQuoteOnRenewalModal.cancelText'),
      mask: false,
      centered: true,
      okButtonProps: { 'data-cy': 'modal-ok-button' },
      cancelButtonProps: { 'data-cy': 'modal-cancel-button' },
      onOk: () => this.handleCreateQuoteOnRenewal(),
    });
  };

  async handleCreateQuoteOnPolicy() {
    const { createQuoteOnPolicy, match, t } = this.props;
    const { sipasuuid } = match.params;
    const response = await createQuoteOnPolicy(sipasuuid);

    if (response.successful) {
      showToastMessage({ message: t('widgets.policyDetails.createQuoteOnPolicySuccessMessage'), type: 'success' });
      if (response.messages) {
        showMultipleToasts(response.messages);
      }
    } else {
      showToastMessage({ message: t('widgets.policyDetails.createQuoteOnPolicyFailedMessage'), type: 'error' });
      if (response.messages) {
        showMultipleToasts(response.messages);
      }

      if (response.errors) {
        showMultipleToasts(response.errors);
      }
    }
  }

  async handleCreateQuoteOnRenewal() {
    const { createQuoteOnRenewal, match, t } = this.props;
    const { sipasuuid } = match.params;
    const response = await createQuoteOnRenewal(sipasuuid);

    if (response.successful) {
      showToastMessage({ message: t('widgets.policyDetails.createQuoteOnRenewalSuccessMessage'), type: 'success' });
      if (response.messages) {
        showMultipleToasts(response.messages);
      }
    } else {
      showToastMessage({ message: t('widgets.policyDetails.createQuoteOnRenewalFailedMessage'), type: 'error' });
      if (response.messages) {
        showMultipleToasts(response.messages);
      }

      if (response.errors) {
        showMultipleToasts(response.errors);
      }
    }
  }

  async fetchAssets() {
    const { match, getPartyById, setRecentlyViewedCustomer } = this.props;
    const { customerid } = match.params;

    setRecentlyViewedCustomer(customerid);
    getPartyById(customerid);
  }

  render() {
    const { t, match } = this.props;
    const { customerid, sipasuuid } = match.params;
    const widgetLocalizationSalesFlow = JSON.stringify(
      t('tia-agent-sales-flow-widget:widget', { returnObjects: true })
    );
    const documentFeature = getFeatureFlagsConfiguration('customer.documentsWidget.policy.view');

    const enableClauseSearch = getFeatureFlagsConfiguration(featureFlags.customer.policyView.searchClauses);
    const enableRiskToggleOnLeft = getFeatureFlagsConfiguration(featureFlags.customer.policyView.riskToggleOnLeft);

    return (
      <Row gutter={40}>
        <Col xs={24} lg={8}>
          <FeatureFlag featureFlag={featureFlags.customer.policyView.customerDetail}>
            <CustomerDetail addPaymentDetails={this.handleAddPaymentDetails} />
          </FeatureFlag>
          <FeatureFlag featureFlag={featureFlags.customer.policyView.createPaymentDetail}>
            <CreatePaymentDetail
              partyId={customerid}
              showPaymentModal={this.state.showPaymentModal}
              showCustomWidget={this.state.showCustomWidget}
              handleShowCustomWidget={this.handleShowCustomWidget}
              closePaymentModal={this.closePaymentModal}
            />
          </FeatureFlag>
          <FeatureFlag featureFlag={featureFlags.customer.policyView.viewPolicyTimeline}>
            <PolicyTimeline
              setClickedPolicyVersionDateAndStatus={this.setClickedPolicyVersionDateAndStatus}
              partyId={customerid}
            />
          </FeatureFlag>
          <FeatureFlag featureFlag={featureFlags.customer.documentsWidget.policy.view.widget}>
            <Documents
              config={getFeatureFlagsConfiguration('customer.documentsWidget.policy.view')}
              sipasUuid={sipasuuid}
              partyId={customerid}
              searchMode={SearchMode.SipasUuid}
              featureFlag={documentFeature}
            />
          </FeatureFlag>
        </Col>
        <Col xs={24} lg={16}>
          <div style={headerStyles}>
            <span style={headlineStyles}>
              {t(
                `widgets.policyDetails.title.spdPolicyStatus.${this.state.policyStatus}`,
                t('widgets.policyDetails.title.fallback')
              )}
            </span>
            {this.state.policyStatus &&
              this.state.policyStatus !== 'C' &&
              this.state.clickedPolicy.status === 'active' && (
                <div style={{ display: 'flex', gap: '0.5rem', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
                  <FeatureFlag featureFlag={featureFlags.customer.policyView.showNewClaimButton}>
                    <Link to={newClaimPath(customerid, sipasuuid)}>
                    <Button
                      data-cy="view-new-claim-button"
                      type="secondary"
                      >
                      {t('widgets.policyDetails.newClaimButton')}
                    </Button>
                    </Link>
                  </FeatureFlag>
                  <FeatureFlag featureFlag={featureFlags.customer.policyView.showQuoteOnRenewalButton}>
                    <Button
                      data-cy="view-create-quote-on-renewal-button"
                      type="secondary"
                      onClick={() => this.openCreateQuoteOnRenewalModal(t)}>
                      {t('widgets.policyDetails.createQuoteOnRenewalButton')}
                    </Button>
                  </FeatureFlag>
                  <FeatureFlag featureFlag={featureFlags.customer.policyView.showQuoteOnPolicyButton}>
                    <Button
                      data-cy="view-create-quote-on-policy-button"
                      type="secondary"
                      onClick={() => this.openCreateQuoteOnPolicyModal(t)}>
                      {t('widgets.policyDetails.createQuoteOnPolicyButton')}
                    </Button>
                  </FeatureFlag>
                  <Link to={editQuotePath(customerid, sipasuuid)}>
                    <Button data-cy="view-edit-policy-button" type="primary">
                      {t('widgets.policyDetails.editPolicyButton')}
                    </Button>
                  </Link>
                </div>
              )}
          </div>
          <FeatureFlag featureFlag={featureFlags.customer.policyView.agentSalesFlowWidget}>
            <AgentSalesFlowWidget
              key={this.state.clickedPolicy.policyVersionDate}
              policyUuid={sipasuuid}
              partyId={customerid}
              mode="preview"
              localization={widgetLocalizationSalesFlow}
              setPolicyStatus={this.setPolicyStatus}
              policyUuidVersionDate={
                this.state.clickedPolicy.status !== 'active' ? this.state.clickedPolicy.policyVersionDate : null
              }
              enableClauseSearch={enableClauseSearch}
              enableRiskToggleOnLeft={enableRiskToggleOnLeft}
            />
          </FeatureFlag>
        </Col>
      </Row>
    );
  }
}

Policies.propTypes = {
  match: object.isRequired,
  customerDetails: object.isRequired,
  setRecentlyViewedCustomer: func.isRequired,
  createQuoteOnPolicy: func,
  createQuoteOnRenewal: func,
  t: func,
  getPartyById: func,
};

const mapStateToProps = (state, ownProps) => ({
  customerDetails: getCustomerDetails(state),
  policyList: getQuote(state, ownProps),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRecentlyViewedCustomer,
      getPartyById,
      createQuoteOnPolicy,
      createQuoteOnRenewal,
    },
    dispatch
  );

export default withTranslation(['portal', 'tia-agent-sales-flow-widget'])(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Policies))
);
