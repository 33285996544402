import { policiesNormalizer } from './helpers';
import { addUpsertEntities } from '../../entities/actionCreators';
import { POLICIES_ENTITIES } from './consts';
import {
  GET_POLICIES_BY_PARTY_ID_ERROR,
  GET_POLICIES_BY_PARTY_ID_REQUEST,
  GET_POLICIES_BY_PARTY_ID_SUCCESS,
  POST_CREATE_QUOTE_ON_POLICY_ERROR,
  POST_CREATE_QUOTE_ON_POLICY_REQUEST,
  POST_CREATE_QUOTE_ON_POLICY_SUCCESS,
  POST_CREATE_QUOTE_ON_RENEWAL_ERROR,
  POST_CREATE_QUOTE_ON_RENEWAL_REQUEST,
  POST_CREATE_QUOTE_ON_RENEWAL_SUCCESS,
} from '../../actionTypes';
import { apiConnector } from '../../../utils/apiConnector';
import { queryEndpoints } from '../../../api/api-query'

const getPoliciesPerPartyId = (partyId) => async (dispatch) => {
  try {
    const response = await apiConnector().policy.getPolicies(partyId, ['Policies']);
    const normalizedResponse = policiesNormalizer(response.data, 'sipasUuid');

    dispatch(
      addUpsertEntities({
        entities: normalizedResponse.data,
        entityType: POLICIES_ENTITIES,
      })
    );

    return {
      ids: Object.keys(normalizedResponse.data),
      filters: normalizedResponse.filters,
      status: response.status,
    };
  } catch (err) {
    console.error('Error: ', err.toString());
  }
};

export const _getCustomerPoliciesPerId = (id) => async (dispatch) => {
  dispatch({ type: GET_POLICIES_BY_PARTY_ID_REQUEST });

  try {
    const payload = await dispatch(getPoliciesPerPartyId(id));

    dispatch({ type: GET_POLICIES_BY_PARTY_ID_SUCCESS, payload: { ...payload, partyId: id } });
  } catch (err) {
    dispatch({ type: GET_POLICIES_BY_PARTY_ID_ERROR, payload: err });
  }
};

export const createQuoteOnPolicy = (sipasuuid) => async (dispatch) => {
  dispatch({ type: POST_CREATE_QUOTE_ON_POLICY_REQUEST });
  try {
    const response = await queryEndpoints.createQuoteOnPolicy({ params: { uuid:sipasuuid } });
    const data = await response.json();

    if (!data.successful) {
      throw data;
    }

    dispatch({ type: POST_CREATE_QUOTE_ON_POLICY_SUCCESS });

    return data;
  } catch (error) {
    dispatch({ type: POST_CREATE_QUOTE_ON_POLICY_ERROR });
    return error;
  }
};

export const createQuoteOnRenewal = (sipasuuid) => async (dispatch) => {
  dispatch({ type: POST_CREATE_QUOTE_ON_RENEWAL_REQUEST });
  try {
    const response = await queryEndpoints.createQuoteOnRenewal({ params: { uuid:sipasuuid } });
    const data = await response.json();

    if (!data.successful) {
      throw data;
    }

    dispatch({ type: POST_CREATE_QUOTE_ON_RENEWAL_SUCCESS });

    return data;
  } catch (error) {
    dispatch({ type: POST_CREATE_QUOTE_ON_RENEWAL_ERROR });

    return error;
  }
};
