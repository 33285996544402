import {
  AddRecordPayload,
  TableRowClickPayload,
  CancelPayload,
  SubmitSuccessPayload,
  SubmitValidationFailedPayload,
  TableRowEditPayload,
  TableRowDeleteFailedPayload,
  TableRowDeleteSuccessPayload,
  WidgetErrorPayload,
  TableCellClickPayload,
  SubmitFailedPayload,
  SearchConfigurationFailedPayload,
  SearchConfigurationSuccessPayload,
  SearchPayload,
} from '@tia/custom-widget/features/event-dispatcher/types';
import { openNotification } from '../../utils/apiSetup';

const eventMessagesGetter = (eventName: string) => {
  return {
    title: eventName,
    message: 'Event has been handled by notification',
  };
};

export const handleCancelEvent = (_detail: CancelPayload) => {
  openNotification({
    severityLevel: 'info',
    ...eventMessagesGetter('cancel'),
  });
  console.log('cancel event captured');
};

export const handleSubmitSuccess = (data: SubmitSuccessPayload) => {
  openNotification({
    severityLevel: 'success',
    ...eventMessagesGetter('submit-success'),
  });
  console.log('submit-success event captured', data);
};

export const handleSubmitFailed = (data: SubmitFailedPayload) => {
  openNotification({
    severityLevel: 'error',
    ...eventMessagesGetter('submit-failed'),
  });

  console.log('submit-failed event captured', data);
};

export const handleSubmitValidationFailed = (data: SubmitValidationFailedPayload) => {
  openNotification({
    severityLevel: 'error',
    ...eventMessagesGetter('submit-validation-failed'),
  });
  console.log('submit-validation-failed event captured', data);
};

export const handleTableRowEdit = (data: TableRowEditPayload) => {
  openNotification({
    severityLevel: 'error',
    ...eventMessagesGetter('table-row-edit'),
  });
  console.log('table-row-edit event captured', data);
};

export const handleTableRowDeleteFailed = (data: TableRowDeleteFailedPayload) => {
  openNotification({
    severityLevel: 'error',
    ...eventMessagesGetter('table-row-delete-failed'),
  });
  console.log('table-row-delete-failed event captured', data);
};

export const handleTableRowDeleteSuccess = (data: TableRowDeleteSuccessPayload) => {
  openNotification({
    severityLevel: 'error',
    ...eventMessagesGetter('table-row-delete-success'),
  });
  console.log('table-row-delete-success event captured', data);
};
export const handleGenericError = (err: WidgetErrorPayload) => {
  openNotification({
    severityLevel: 'error',
    ...eventMessagesGetter('Generic Error'),
  });
  console.log('Generic Error', err);
};

export const handleAddRecord = (_detail: AddRecordPayload) => {
  openNotification({
    severityLevel: 'info',
    ...eventMessagesGetter('Add Record'),
  });
};

export const handleTableRowClick = (detail: TableRowClickPayload) => {
  openNotification({
    severityLevel: 'success',
    ...eventMessagesGetter('Table Row Click'),
  });
  console.log('table-row-click event captured', detail);
};

export const handleTableCellClick = (detail: TableCellClickPayload) => {
  openNotification({
    severityLevel: 'success',
    ...eventMessagesGetter('Table Cell Click'),
  });
  console.log('table-cell-click event captured', detail);
};

export const handleSearch = (err: SearchPayload) => {
  openNotification({
    severityLevel: 'info',
    ...eventMessagesGetter('Search triggered'),
  });
  console.log('Search triggered', err);
};

export const handleSCFailed = (err: SearchConfigurationFailedPayload) => {
  openNotification({
    severityLevel: 'error',
    ...eventMessagesGetter('SC Failed'),
  });
  console.log('SC Failed', err);
};

export const handleSCSuccess = (data: SearchConfigurationSuccessPayload) => {
  openNotification({
    severityLevel: 'success',
    ...eventMessagesGetter('SC Success'),
  });
  console.log('SC Success', data);
};
