import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import { getUser } from '../../utils/idToken';
import { useTiaAuth } from '@tia/authenticator';
import { PATH_LOGOUT } from '../../router/paths';

const UserActions = () => {
  const user = useMemo(getUser, []);
  const { t } = useTranslation();
  const { logout } = useTiaAuth();
  const userName = user ? user.name : <UserOutlined />;

  const menu = () => {
    const menuItems = [];

    menuItems.push({
      label: (
        <Link to={PATH_LOGOUT} onClick={() => logout()}>
          <LogoutOutlined style={{ marginRight: '12px' }} />
          {t('menus.topMenu.dropdown.logOut')}
        </Link>
      ),
      key: 'logout-button',
    });

    return <Menu data-cy="log-out-button" style={{ marginTop: '5px' }} items={menuItems}></Menu>;
  };

  return (
    <>
      <Dropdown key="1" trigger={['click']} placement="bottomLeft" overlay={menu()}>
        <span data-cy="user-dropdown" style={nameStyle}>
          {userName}
          <DownOutlined style={{ color: '#007A84', marginLeft: '10px' }} />
        </span>
      </Dropdown>
    </>
  );
};

const nameStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  cursor: 'pointer',
};

UserActions.propTypes = {};

export default UserActions;
