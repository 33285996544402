"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchRequest = exports.fetchRequestWithPagination = exports.fetchBinaryWithResponseWrapper = exports.fetchRequestBase = exports.removeQuotes = exports.setSite = exports.setLanguageHeader = exports.setAccessTokenKey = void 0;
var accessTokenKey = '';
var language = '';
var siteName = '';
/**
 * Set accessToken Key
 * @param key
 */
var setAccessTokenKey = function (key) {
    accessTokenKey = key;
};
exports.setAccessTokenKey = setAccessTokenKey;
/**
 * Set language header
 * @param lang
 */
var setLanguageHeader = function (lang) {
    if (lang) {
        language = lang;
    }
};
exports.setLanguageHeader = setLanguageHeader;
var setSite = function (site) {
    if (site) {
        siteName = site;
    }
};
exports.setSite = setSite;
var removeQuotes = function (val) {
    return val === null ? null : /^".*"$/.test(val) ? JSON.parse(val) : val;
};
exports.removeQuotes = removeQuotes;
/**
 * Try to get access token from localStorage
 * Throw error if attempt fails
 */
var tryGetAccessToken = function () {
    var accessToken = localStorage.getItem(accessTokenKey);
    var normalizedToken = (0, exports.removeQuotes)(accessToken);
    if (!accessTokenKey) {
        throw Error('Access token has not been set');
    }
    if (!normalizedToken) {
        throw Error("No access token found for key: ".concat(accessTokenKey));
    }
    return normalizedToken;
};
/**
 * Base request for the Api Connector
 * @param request
 * @param BASE_URL
 */
var fetchRequestBase = function (request, BASE_URL) { return __awaiter(void 0, void 0, void 0, function () {
    var accessToken, fetchParams;
    return __generator(this, function (_a) {
        accessToken = tryGetAccessToken();
        fetchParams = {
            method: request.method,
            headers: new Headers({
                Authorization: "Bearer ".concat(accessToken),
                'Accept-Language': language,
                'X-site-name': siteName,
            }),
        };
        if (request.data instanceof FormData) {
            fetchParams.body = request.data;
        }
        else if (request.data) {
            fetchParams.body = JSON.stringify(request.data);
            fetchParams.headers.append('Content-Type', 'application/json');
        }
        return [2 /*return*/, fetch("".concat(BASE_URL).concat(request.url), fetchParams)];
    });
}); };
exports.fetchRequestBase = fetchRequestBase;
/**
 * Fetch a binary file for download
 * @param request
 * @param BASE_URL
 */
var fetchBinaryWithResponseWrapper = function (request, BASE_URL) { return __awaiter(void 0, void 0, void 0, function () {
    var baseRequest, jsonResponse, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, exports.fetchRequestBase)(request, BASE_URL)];
            case 1:
                baseRequest = _a.sent();
                if (!!baseRequest.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, baseRequest.json()];
            case 2:
                jsonResponse = _a.sent();
                return [2 /*return*/, {
                        data: jsonResponse.data,
                        successful: false,
                        error: jsonResponse.errors,
                        message: jsonResponse.messages,
                        status: baseRequest.status,
                    }];
            case 3: return [4 /*yield*/, baseRequest.blob()];
            case 4:
                response = _a.sent();
                return [2 /*return*/, {
                        data: response,
                        successful: baseRequest.ok,
                        error: new Array(),
                        message: [],
                        status: baseRequest.status,
                    }];
        }
    });
}); };
exports.fetchBinaryWithResponseWrapper = fetchBinaryWithResponseWrapper;
/**
 * Fetch including pagination
 * @param request
 * @param BASE_URL
 */
var fetchRequestWithPagination = function (request, BASE_URL) { return __awaiter(void 0, void 0, void 0, function () {
    var baseRequest, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, exports.fetchRequestBase)(request, BASE_URL)];
            case 1:
                baseRequest = _a.sent();
                return [4 /*yield*/, baseRequest.json()];
            case 2:
                response = _a.sent();
                return [2 /*return*/, {
                        data: response.data,
                        page: response.page,
                        successful: response.successful,
                        error: response.errors,
                        message: response.messages,
                        status: baseRequest.status,
                    }];
        }
    });
}); };
exports.fetchRequestWithPagination = fetchRequestWithPagination;
/**
 * Fetch unwrapped data
 * @param request
 * @param BASE_URL
 */
var fetchRequest = function (request, BASE_URL) { return __awaiter(void 0, void 0, void 0, function () {
    var baseRequest, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, exports.fetchRequestBase)(request, BASE_URL)];
            case 1:
                baseRequest = _a.sent();
                return [4 /*yield*/, baseRequest.json()];
            case 2:
                response = _a.sent();
                return [2 /*return*/, response.data];
        }
    });
}); };
exports.fetchRequest = fetchRequest;
