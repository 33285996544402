import React, { Fragment, Suspense, useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getRoutesBasedOnRoles } from '../router/helpers';
import ROUTER_CONFIG from '../router/routesConfig';
import ROLE_ACCESS_RIGHTS, { ROLE_ANONYMOUS } from '../router/roles';
import Menus from '../menus/Menus';
import { useTiaAuth } from '@tia/authenticator';
import {
  setAgentType,
  _getAgentConfigurations as getAgentConfigurations,
} from '../state/api/configurations/actionCreators';
import './App.scss';
import './tailwind.css';
import { I18nextProvider, withTranslation } from 'react-i18next';
import { apiConnector } from '../utils/apiConnector';
import { AgentAuthProvider } from '../components/AgentAuthProvider/AgentAuthProvider';
import { State } from '../configureStore';
import {useAtom, useSetAtom} from 'jotai';
import { i18n } from '../i18n';
import { RESET } from 'jotai/utils';
import {lastVisitedAtom, accessTokenAtom, idTokenAtom, featureFlagsChangeAtom} from '../atoms/atoms';

interface Props {
  agentConfigurationLoaded: boolean;
  agentConfigurationLoading: boolean;
  getAgentConfigurations: () => void;
  setAgentType: (agentType: string) => void;
}

const App = (props: Props) => {
  const { agentConfigurationLoaded, agentConfigurationLoading, getAgentConfigurations, setAgentType } = props;
  const location = useLocation();
  const [flag, setFlag] = useState(false);
  const auth = useTiaAuth();
  const [loggedIn, setLoggedIn] = useState(false);
  const [anonymousRoutes, setAnonymousRoutes] = useState<JSX.Element[]>([]);
  const [rolesRoutes, setRolesRoutes] = useState<JSX.Element[]>([]);
  const [lastVisited, setLastVisited] = useAtom(lastVisitedAtom);
  const [accessToken] = useAtom(accessTokenAtom);
  const [idToken] = useAtom(idTokenAtom);
  const featureFlagsAtom = useSetAtom(featureFlagsChangeAtom);

  useEffect(() => {
    if (anonymousRoutes.length === 0) {
      setAnonymousRoutes(
        ROLE_ACCESS_RIGHTS[ROLE_ANONYMOUS].map((route) => (
          <Route
            key={ROUTER_CONFIG[route].path}
            path={ROUTER_CONFIG[route].path}
            component={ROUTER_CONFIG[route].component}
          />
        ))
      );
    }
  }, [anonymousRoutes]);

  useEffect(() => {
    if (rolesRoutes.length === 0 && loggedIn) {
      const routes = getRoutesBasedOnRoles();

      routes.sort((a, b) => Number(b.exact) - Number(a.exact));
      setRolesRoutes(
        routes.map((route) => (
          <Route key={route.path} path={route.path} component={route.component} exact={route.exact} />
        ))
      );
    }
  }, [rolesRoutes, loggedIn]);

  //accessToken atom should be used, so that "onMount" callback function would be executed later
  //this way it initializes the atom's state from local storage
  useEffect(() => {
    const fetchData = async () => {
      const accessTokenValue = accessToken;
      const idTokenValue = idToken;
    };

    fetchData();
  }, []);

  useEffect(() => {
    const setFeatureFlags = async () => {
      const response = await apiConnector().utility.getAgentType();

      if (response) {
        setAgentType(response.data);
        if (window.featureFlagInternal && response.data === 'internal') {
          window.featureFlag = window.featureFlagInternal;
        } else if (window.featureFlagExternal && response.data === 'external') {
          window.featureFlag = window.featureFlagExternal;
        }
          featureFlagsAtom(window.featureFlag);
      }

      setFlag(!flag);

      delete window.featureFlagExternal;
      delete window.featureFlagInternal;
    };

    if (loggedIn && !agentConfigurationLoaded && !agentConfigurationLoading) {
      setFeatureFlags();

      getAgentConfigurations();
    }
  }, [agentConfigurationLoaded, agentConfigurationLoading, loggedIn]);

  useEffect(() => {
    (async () => {
      const authenticated = await auth.authClient.isAuthenticated();

      setLoggedIn(authenticated);
    })();
  }, []);

  const loggedInContent = () => {
    if (loggedIn) {
      if (`${location.pathname}${location.search}` === lastVisited) {
        setLastVisited(RESET);
      }

      return (
        <Menus rerender={flag}>
          <Switch>{rolesRoutes}</Switch>
        </Menus>
      );
    }

    return null;
  };

  const AnonymousPaths = () => <Switch>{anonymousRoutes}</Switch>;

  return (
    <Fragment>
      <AnonymousPaths />
      {loggedInContent()}
    </Fragment>
  );
};

const mapStateToProps = (state: State) => ({
  agentConfigurationLoading: state.api.configurations.agentConfigurations.isFetching,
  agentConfigurationLoaded: state.api.configurations.agentConfigurations.isLoaded,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getAgentConfigurations,
      setAgentType,
    },
    dispatch
  );

const AppComponent = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(App));

const SuspenseApp = () => {
  return (
    <Suspense fallback="loading">
      <I18nextProvider i18n={i18n}>
        <AgentAuthProvider>
          <AppComponent />
        </AgentAuthProvider>
      </I18nextProvider>
    </Suspense>
  );
};
export default SuspenseApp;
