import React, { useEffect, useState } from 'react';
import { useTiaAuth } from '@tia/authenticator';
import { PATH_ROUTE, PATH_LOGOUT } from '../../../router/paths';
import AuthenticationMessage from '../../../components/Unauthorized/AuthenticationMessage';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { string } from 'prop-types';
import { useAtom } from 'jotai';
import { lastVisitedAtom } from '../../../atoms/atoms';

const LoginView = ({ errors }) => {
  const { logout, login, user } = useTiaAuth();
  const { t } = useTranslation();
  const [forceLogout, setForceLogout] = useState(false);
  const history = useHistory();
  const [lastVisited, setLastVisited] = useAtom(lastVisitedAtom);

  useEffect(() => {
    if (user === null || user.expired) {
      setLastVisited(`${history.location.pathname}${history.location.search}`);
      history.push(PATH_LOGOUT);
      setForceLogout(true);
    } else if (user.access_token && !user.expired) {
      window.invalidateTiaWidgetsAuth();

      history.push(lastVisited ? lastVisited : PATH_ROUTE);
    }
  }, [user]);

  if (errors) {
    return (
      <AuthenticationMessage headline={t('pages.login.callbackView.authenticationErrorHeadline')} description={errors}>
        <Button style={{ width: '100%', marginTop: '38px' }} type="primary" onClick={() => logout()}>
          {t('pages.login.callbackView.logoutButton')}
        </Button>
      </AuthenticationMessage>
    );
  }

  return forceLogout ? (
    <AuthenticationMessage headline={t('pages.login.loggedOutView.headline')}>
      <Button style={{ width: '100%', marginTop: '20px' }} type="primary" onClick={() => login()}>
        {t('pages.login.loggedOutView.loginButton')}
      </Button>
    </AuthenticationMessage>
  ) : (
    <AuthenticationMessage headline={t('pages.login.callbackView.authenticationMessage')} />
  );
};

export default LoginView;

LoginView.propTypes = {
  errors: string,
};
